import Wheel from "../../interface/WheelInterface";

function wheelShape(ctx: CanvasRenderingContext2D, wheel: Wheel, fontFamily: string, primaryColor: string, contrastColor: string, segments: string[], segColors: string[], size: number) {
  
  const drawSegment = (wheel: Wheel, key: number, lastAngle: number, angle: number): any => {
    const value = segments[key];
    const centerX = wheel.centerX;
    const centerY = wheel.centerY;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key];
    ctx.fill();
    if (segments.length !== 1) {
      ctx.stroke();
    }
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);
    ctx.fillStyle = contrastColor;
    ctx.font = "bold 1em " + fontFamily;
    ctx.fillText(value.substr(0, 21), size / 2 + 20, 0);
    ctx.restore();
  };

  const centerX = wheel.centerX;
  const centerY = wheel.centerY;
  let lastAngle = wheel.theta;
  const len = segments.length;
  const PI2 = Math.PI * 2;
  ctx.lineWidth = 1;
  ctx.strokeStyle = primaryColor;
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  ctx.font = "1em " + fontFamily;
  for (let i = 1; i <= len; i++) {
    const angle = PI2 * (i / len) + wheel.theta;
    drawSegment(wheel, i - 1, lastAngle, angle);
    lastAngle = angle;
  }

  // Draw outer circle
  ctx.beginPath();
  ctx.arc(centerX, centerY, size, 0, PI2, false);
  ctx.closePath();

  ctx.lineWidth = 10;
  ctx.strokeStyle = primaryColor;
  ctx.stroke();
}

function NeedleShape(ctx: CanvasRenderingContext2D, wheel: Wheel, fontFamily: string, primaryColor: string, contrastColor: string, buttonText: string) {

  const centerX = wheel.centerX;
  const centerY = wheel.centerY;

  const PI2 = Math.PI * 2;

  // Draw a center circle
  ctx.beginPath();
  ctx.arc(centerX, centerY, 50, 0, PI2, false);
  ctx.closePath();
  ctx.fillStyle = primaryColor;
  ctx.lineWidth = 10;
  ctx.strokeStyle = contrastColor;
  ctx.fill();
  ctx.font = "bold 1em " + fontFamily;
  ctx.fillStyle = contrastColor;
  ctx.textAlign = "center";
  ctx.fillText(buttonText, centerX, centerY + 3);
  ctx.stroke();

  ctx.lineWidth = 1;
  ctx.strokeStyle = contrastColor;
  ctx.fillStyle = contrastColor;
  ctx.beginPath();
  ctx.moveTo(centerX + 50, centerY + 20);
  ctx.lineTo(centerX + 50, centerY - 20);
  ctx.lineTo(centerX + 70, centerY);
  ctx.closePath();
  ctx.fill();
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = primaryColor;
  ctx.font = "bold 1.5em " + fontFamily;

}
export { wheelShape, NeedleShape };
