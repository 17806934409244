function repeatArray(a: string[], b: string[]): string[] {
  let c: string[] = [];
  while (c.length < a.length) {
      b.forEach(item => {
          if (c.length < a.length) {
              c.push(item);
          }
      });
    }
  return c;
}

export default repeatArray;