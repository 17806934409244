import { useEffect, useState, useRef, useCallback } from "react";
import randomTruncSkewNormal from "../function/truncatedNormal";
import showInterval from "./Interval";
import showResult from "./Result";
import useCanvasContext from "../function/useCanvasContext";
import Wheel from "../interface/WheelInterface";
import WheelComponentProps from "../interface/WheelComponentProps";
import { wheelShape, NeedleShape } from "./canvas/wheelShape";

const WheelComponent = ({
  segments,
  segColors,
  primaryColor = "black",
  contrastColor = "white",
  buttonText = "Spin",
  size = window.innerWidth,
  fontFamily = "proxima-nova",
}: WheelComponentProps) => {
  // const canvasId = useRef(`canvas-${randomString()}`);
  // const wheelId = useRef(`wheel-${randomString()}`);
  const { canvasRef, isIE } = useCanvasContext();
  const dimension = (size + 20) * 2;
  const [winSegment, setWinSegment] = useState<string>("");
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isCounting, setIsCounting] = useState(false);
  const [randonInterval, setRandomInveral] = useState<number>(250);

  const spinStartTime = useRef<number>(0);

  let animationFrameId: number | null = null;

  const wheelRef = useRef<Wheel>({ theta: 0, omega: 2 * Math.PI / (segments.length), centerX: (size + 20), centerY: (size + 20), len: segments.length });

  const random = () => {
    const rng = randomTruncSkewNormal({
      range: [10, 100],
      mean: 50,
      stdDev: 15
    });
    return Math.floor(rng);
  };

  const spin = (duration: number): void => {
    console.log(duration, randonInterval);
    if (!isStarted) {
      setIsStarted(true);
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
      }

      animateRotation(duration, wheelRef.current.theta);
    }
  };

  const updateWheel = (timeElapsed: number, duration: number, startAngle: number) => {
    const wheel = wheelRef.current;
    const progress = Math.min(timeElapsed / duration, 1);
    wheel.theta = startAngle + (wheel.omega * duration * progress / randonInterval);
  };

  const animateRotation = (duration: number, startAngle: number): void => {
    let startTime: number | null = null;
    const rotate = (currentTime: number) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      updateWheel(timeElapsed, duration, startAngle);
      wheelDraw(wheelRef.current); // Redraw the wheel at the new angle
      if (timeElapsed < duration) {
        animationFrameId = requestAnimationFrame(rotate);
      } else {
        setIsCounting(false);
        setIsStarted(false); // Reset isStarted to allow another spin
        onFinished();
      }
    };

    animationFrameId = requestAnimationFrame(rotate);
  };

  const onFinished = () => {
    const wheel = wheelRef.current;
    wheel.theta = wheel.theta % (2 * Math.PI); // Normalize theta
    const k = Math.floor(wheel.theta / (2 * Math.PI) * wheel.len);
    const result = (wheel.len - k) - 1;
    setWinSegment(segments[result]);
    const rng = random();
    setRandomInveral(rng);
    console.log(`theta radian = ${wheelRef.current.theta}, theta degree = ${wheelRef.current.theta / (2 * Math.PI) * 360}, test = ${k}, test = ${result}`);
  }
  const startClick = () => {
    if (!isCounting) {
      spinStartTime.current = Date.now();
      setIsCounting(true);
    }
  };

  // const spinTest = () => {
  //   spin(125);
  // }

  const endClick = () => {
    if (isCounting) {
      const duration = Date.now() - spinStartTime.current;
      spinStartTime.current = Date.now();
      console.log(`Spin Duration: ${duration}ms`);
      spin(duration);
    }
  };

  const drawWheel = useCallback((wheel: Wheel) => {
    const canva = canvasRef.current;
    if (!canva) return false;
    const ctx: CanvasRenderingContext2D | null = canva ? canva.getContext('2d') : null;
    if (!ctx) return false;
    wheelShape(ctx, wheel, fontFamily, primaryColor, contrastColor, segments, segColors, size);
  }, [canvasRef, primaryColor, contrastColor, fontFamily, segments, segColors, size]);

  const drawNeedle = useCallback((wheel: Wheel) => {
    const canva = canvasRef.current;
    if (!canva) return false;
    const ctx: CanvasRenderingContext2D | null = canva ? canva.getContext('2d') : null;
    if (!ctx) return false;
    NeedleShape(ctx, wheel, fontFamily, primaryColor, contrastColor, buttonText)
  }, [canvasRef, primaryColor, contrastColor, fontFamily, buttonText]);

  const clear = useCallback(() => {
    const canva = canvasRef.current;
    if (!canva) return false;
    const ctx: CanvasRenderingContext2D | null = canva ? canva.getContext('2d') : null;
    if (!ctx) return false;
    ctx.clearRect(0, 0, dimension, dimension);
  }, [canvasRef, dimension]);

  const wheelDraw = useCallback((wheel: Wheel): any => {
    clear();
    drawWheel(wheel);
    drawNeedle(wheel);
  }, [clear, drawWheel, drawNeedle]);

  useEffect(() => {
    const rng = random();
    setRandomInveral(rng);
    wheelDraw(wheelRef.current);
  }, [wheelDraw]);

  useEffect(() => {
    wheelRef.current = {
      ...wheelRef.current,
      omega: 2 * Math.PI / segments.length,
      len: segments.length,
    };
  }, [segments]);

  if (isIE) {
    return (
      <p>Internet Explorer is not supported.</p>
    );
  };

  return (
    <div>
      {showInterval(randonInterval)}
      <canvas
        ref={canvasRef}
        onMouseDown={startClick}
        onMouseUp={endClick}
        width={dimension}
        height={dimension}
        style={{
          pointerEvents: "auto",
        }}
      />
      {showResult(winSegment)}
    </div>
  );
};

export default WheelComponent;

