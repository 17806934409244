function showResult(result: string) {
  if (result) {
    return <h4>
      抽中{result}！
    </h4>
  }
  else {
    return <div></div>
  }
}
export default showResult;