import WheelComponent from "./component/Wheel";
import repeatArray from "./function/repeatArray";
import './App.css';
import useLinesFromTextbox from "./component/TextBox";

function App() {
  const { text, setText, lines } = useLinesFromTextbox();
  const segColorsArray = [
    '#F0CF50',
    '#34A24F',
    '#3DA5E0',
    '#EE4040',
    '#815CD1'
  ]
  const segColors = repeatArray(lines, segColorsArray);
  return (
    <div className="main">
      <div className="div1">
        <WheelComponent
          segments={lines}
          segColors={segColors}
          primaryColor='black'
          contrastColor='white'
          buttonText='Spin'
          size={250}
        />
      </div>
      <div className="div2">
            <textarea 
                value={text} 
                onChange={(e) => setText(e.target.value)}
                rows={40}
                cols={50}
            />
      </div>
    </div>
  );
}

export default App;
