import { useEffect, useState, useRef } from "react";
function useCanvasContext() {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isIE, setIsIE] = useState<boolean>(false);
  useEffect(() => {
    setIsIE((navigator.userAgent.indexOf("MSIE") !== -1));
  }, []);

  return { canvasRef, isIE };
}
export default useCanvasContext;