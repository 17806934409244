import { useEffect, useState} from "react";

function useLinesFromTextbox(initialValue: string = '1\n2\n3\n4\n5\n6\n7\n8') {
  const [text, setText] = useState<string>(initialValue);
  const [lines, setLines] = useState<string[]>([]);

  useEffect(() => {
      setLines(text.split('\n'));
  }, [text]);

  return { text, setText, lines };
}

export default useLinesFromTextbox;